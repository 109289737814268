import React from 'react';
import { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import './App.css';
import { ToastContainer } from 'react-toastify';
import "./Bootstrap/css/bootstrap.min.css"
import 'react-toastify/dist/ReactToastify.css';

const Auth = lazy(() => import("./Components/Auth/Auth"))
const BookAppointment = lazy(() => import("./Components/ServiceManagement/Appointments/BookAppointment"))
const RoutingConfig = lazy(() => import("./Components/RoutingConfig"))
const PrivateRoute = lazy(() => import("./PrivateRoute/PrivateRoute"))
const SuccessMessage = lazy(() => import("./Components/Auth/SucccessMessagePage"))

const App = () => {


  return (
    <Suspense fallback={() => <div>Loading ....</div>}>
      <ToastContainer />
      <Router >
        <Routes>
          <Route path='*' element={<Auth />} />
          <Route pathname="/auth" element={<Auth />} />
          <Route path="book-appointment/*" element={<BookAppointment />} />
          <Route path="successmessage/*" element={<SuccessMessage />} />
          <Route path="v1/*" element={<PrivateRoute> <RoutingConfig /></PrivateRoute>} />
        </Routes>
      </Router>
    </Suspense>
  );
}

export default App;
