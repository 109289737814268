import React, { createContext, useState, useEffect, useContext } from 'react';
const ThemeContext = createContext();
export const useTheme = () => useContext(ThemeContext);
export const ThemeProvider = ({ children }) => {

    const [broken, setBroken] = useState(window.matchMedia("(max-width: 992px)").matches);
    const [isMobile, setMobile] = useState(window.matchMedia("(max-width: 640px)").matches);

    const [showNavbar, setShowNavbar] = useState(false);

    const handleShowNavbar = (e) => {
        e.stopPropagation();
        // setShowNavbar(false)
        setShowNavbar(!showNavbar);
    };
    const handleParentClick = () => setShowNavbar(false)
    useEffect(() => {
        const mediaQuery = window.matchMedia("(max-width: 992px)");
        const mediaQueryMobile = window.matchMedia("(max-width: 640px)");

        const handleMediaChange = (event) => {
            setBroken(event.matches);
        };
        const handleMobileMediaChange = (event) => {
            setMobile(event.matches);
        };
        mediaQuery.addEventListener('change', handleMediaChange);
        mediaQueryMobile.addEventListener('change', handleMobileMediaChange);
        return () => {
            mediaQuery.removeEventListener('change', handleMediaChange);
            mediaQueryMobile.removeEventListener('change', handleMobileMediaChange);
        };
    }, []);


    // const [theme, setTheme] = useState(() => {
    //     const storedTheme = sessionStorage.getItem('theme');
    //     return storedTheme ? storedTheme : 'themeColor';
    // });

    // const [language, setLanguage] = useState(() => {
    //     const storedLanguage = sessionStorage.getItem('language');
    //     return storedLanguage ? storedLanguage : 'English';
    // });

    // useEffect(() => {
    //     document.body.className = `theme-${theme}`;
    //     sessionStorage.setItem('theme', theme);
    // }, [theme]);

    // useEffect(() => { sessionStorage.setItem('language', language) }, [language]);

    // const toggleTheme = () => setTheme(p => (p === 'themeColor' ? 'sassTheam' : 'themeColor'))
    // const toggleLanguage = () => setLanguage(p => (p === 'Arabic' ? 'English' : 'Arabic'))

    return (
        <ThemeContext.Provider value={{ broken, handleShowNavbar, isMobile, showNavbar, handleParentClick }}>
            {children}
        </ThemeContext.Provider>
    );
};